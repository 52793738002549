<template src="./template.html"></template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import correctionReportApi from '@/api/correctionReport'
import listPageMixin from '@/mixin/listPageMixin'
import dateMixin from '@/mixin/dateMixin'
import advancedSearchMixin from '@/mixin/advancedSearchMixin'
import mediaMixin from '@/mixin/mediaMixin'
import masterMixin from '@/mixin/masterMixin'

export default {
  name: 'correctionReport',
  data() {
    const inspectionReportId =
      this.$route.name === 'inspectionReportCorrectionReportList'
        ? this.$route.params.inspection_report_id : null
    return {
      inspection_report_id: inspectionReportId,
      search: {
        dt_from: '',
        dt_to: '',
        office_id: '',
        is_draft: null,
      },
      advancedSearch: {
        conditions: [{
          table: '',
          column: '',
          comp: '',
          field: '',
        }],
        fieldInfo: null,
        options: {},
      },

      showAdvancedSearchForm: false,
      hasInputError: false,
      offices: [],
      save_statuses: [],

      list: [],
      listItemsPerPage: 20,
      previousPage: null,
      currentPage: 1,
      isSearchResultEmpty: false,

      printMaxCopies: 500,

      reportToDelete: null,
    }
  },
  computed: {
    ...mapState('user', {
      user: state => state,
      has_role_admin: state => state.has_role_admin,
      has_role_shunin: state => state.has_role_shunin,
      has_role_sagyou: state => state.has_role_sagyou,
    }),
    listItemCount() {
      return this.list.length
    },
    filteredList() {
      const startIdx =
        this.listItemsPerPage * Math.max((this.currentPage - 1), 0)
      return this.list.slice(startIdx, startIdx + this.listItemsPerPage)
    },
    showConfirmDeleteModal() {
      return this.reportToDelete !== null
    },
  },
  mounted() {
    this.waitForUserAndMasters().then(() => {
      this.prepareMasters()
      this.prepareSearchParams()
      this.doSearch()
    })
  },
  mixins: [dateMixin, listPageMixin, advancedSearchMixin, mediaMixin, masterMixin],
  methods: {
    prepareMasters() {
      this.offices = window.master.offices
      this.save_statuses = this.getListSearchOptionsForSaveStatus()
      const options = this.advancedSearch.options
      const lovs = window.master.lovs
      options.offices = window.master.offices
      options.constructions = window.master.constructions
      options.construction_kinds = lovs.construction_kind.vals_inuse
      options.inspection_results = lovs.inspection_result.vals_inuse
      options.climates = lovs.climate.vals_inuse
      options.save_statuses = this.save_statuses
    },
    prepareSearchParams() {
      let dtFrom, dtTo, officeId, isDraft

      const listSearchParams = this.getListSearchParamsForTheMonth()
      if (listSearchParams.dtStrFrom) {
        dtFrom = listSearchParams.dtStrFrom
      }
      if (listSearchParams.dtStrTo) {
        dtTo = listSearchParams.dtStrTo
      }

      const storage = JSON.parse(sessionStorage.getItem(this.$route.name)) || {
        dtFrom: null,
        dtTo: null,
        officeId: this.user.is_admin ? '' : this.user.office_id,
        isDraft: null,
      }
      if (storage.dtFrom) {
        dtFrom = storage.dtFrom
      }
      if (storage.dtTo) {
        dtTo = storage.dtTo
      }
      if (storage.officeId) {
        officeId = storage.officeId
      }
      if (Object.hasOwn(storage, 'isDraft')) {
        isDraft = storage.isDraft
      }

      if (storage.advancedSearch) {
        this.showAdvancedSearchForm = true
        this.advancedSearch = storage.advancedSearch
      }

      if (storage.previousPage) {
        this.previousPage = storage.previousPage
      }

      this.search = {
        dt_from: dtFrom,
        dt_to: dtTo,
        office_id: officeId,
        is_draft: isDraft,
      }

      this.onDateInput()
    },
    isEditable(report) {
      if (this.user.is_sagyou) {
        // 作業者は自分が作成した報告書のみ編集可能
        return report.inspection_report.inspectors.findIndex(inspector => inspector.id === this.user.id) !== -1
      }
      if (this.user.is_shunin || this.user.is_soukatsu) {
        // 主任・総括は自事業所内の報告書のみ編集可能
        return report.inspection_report.inspector_office_id === this.user.office_id
      }
      if (this.user.is_admin) {
        // 管理者は全て編集可能
        return true
      }
      return false
    },
    saveStatusDisp(isDraft) {
      const saveStatus = this.save_statuses.find(e => e.key === isDraft)
      return saveStatus ? saveStatus.val : ''
    },
    onDateInput() {
      this.hasInputError = false
      if (!this.isDateString(this.search.dt_from)) {
        this.hasInputError = true
      }
      if (!this.isDateString(this.search.dt_to)) {
        this.hasInputError = true
      }
      const dtFormat = Vue.filter('dtFormat')
      const dtStrFrom = dtFormat(this.search.dt_from, 'yyyy/mm/dd')
      const dtStrTo = dtFormat(this.search.dt_to, 'yyyy/mm/dd')
      if ((new Date(dtStrTo) - new Date(dtStrFrom)) > (31 - 1) * 24 * 60 * 60 * 1000) {
        this.hasInputError = true
      }
    },
    async toggleAdvancedSearchForm() {
      if (!this.advancedSearch.fieldInfo) {
        this.advancedSearch.fieldInfo = this.getAdvancedSearchFormInfo('correction_report')
      }
      this.showAdvancedSearchForm = !this.showAdvancedSearchForm
    },
    addAdvancedSearchCondition(condition) {
      this.advancedSearch.conditions.push(condition)
    },
    removeAdvancedSearchCondition(idx) {
      this.advancedSearch.conditions.splice(idx, 1)
    },
    doSearch() {
      if (this.hasInputError) { return }
      this._doSearch()
    },
    _doSearch() {
      const storage = {
        dtFrom: this.search.dt_from,
        dtTo: this.search.dt_to,
        officeId: this.search.office_id,
        isDraft: this.search.is_draft,
      }
      if (this.showAdvancedSearchForm) {
        storage.advancedSearch = this.advancedSearch
      }
      sessionStorage.setItem(this.$route.name, JSON.stringify(storage))

      this.isSearchResultEmpty = false
      this.list = []
      this.setPage(1)

      const obj = {
        from: this.search.dt_from,
        to: this.search.dt_to,
        office_id: this.search.office_id,
        inspection_report_id: this.inspection_report_id,
        is_draft: this.search.is_draft,
      }
      if (this.showAdvancedSearchForm) {
        obj.advanced_search_conditions = this.advancedSearch.conditions
      }
      correctionReportApi.search(obj).then(({ data }) => {
        if (!data || data.length === 0) {
          this.isSearchResultEmpty = true
          return
        }
        this.list = data
        if (this.previousPage) {
          this.setPage(this.previousPage)
          this.previousPage = null
        }
      })
    },
    confirmDeleteReport(e) {
      this.errors = {}
      this.reportToDelete = e
    },
    downloadCsv() {
      correctionReportApi.downloadCsv({ ids: this.list.map(e => e.id) })
        .then(res => {
          const { url, filename } = this.toBlobUrl(res)
          const link = document.createElement('a')
          link.href = url
          link.download = filename
          link.click()
        })
    },
    deleteReport() {
      const obj = {
        id: this.reportToDelete.id,
        inspection_report_id: this.reportToDelete.inspection_report_id,
      }
      correctionReportApi.delete(obj)
        .then(() => {
          this.reportToDelete = null
          this._doSearch()
        })
    },
    setPage(evt) {
      this.currentPage = evt
      const storage = JSON.parse(sessionStorage.getItem(this.$route.name))
      storage.previousPage = this.currentPage
      sessionStorage.setItem(this.$route.name, JSON.stringify(storage))
    },
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
