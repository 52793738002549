<template>
  <div class="advanced-search-form">
    <div class="mb-8" v-for="(condition, idx) in conditions" :key="idx">
      <div class="mb-8" v-if="idx > 0" style="margin-left: 36px;">
        <button class="btn btn-md btn-default"
            :class="{ active: condition.logicalOp === 'AND' }"
            @click="condition.logicalOp = 'AND'">
          AND
        </button>
        <button class="btn btn-md btn-default"
            :class="{ active: condition.logicalOp === 'OR' }"
            @click="condition.logicalOp = 'OR'">
          OR
        </button>
      </div>
      <div class="mb-8">
        <span :style="idx > 8 && 'margin-left: -8px;'">条件{{idx + 1}}</span>
        <select class="ib form-control"
            v-model="condition.column"
            @change="onColumnChange(condition)"
            style="width: 40%;">
          <option v-for="(val, key) in fieldInfo" :value="key" :key="key">
            {{val.disp_name}}
          </option>
        </select>
        <select class="ib form-control"
            v-if="!condition.column"
            v-model="condition.comp"
            style="width: auto;">
          <option value=""></option>
        </select>
        <select class="ib form-control"
            v-else
            v-model="condition.comp"
            style="width: auto;">
          <option :value="fieldInfo[condition.column].type === 'text' ? 'LIKE' : '='">=</option>
          <option value="<="
              v-if="(fieldInfo[condition.column].type === 'time') || (fieldInfo[condition.column].type === 'date')">
            &lt;=
          </option>
          <option value=">="
              v-if="(fieldInfo[condition.column].type === 'time') || (fieldInfo[condition.column].type === 'date')">
            &gt;=
          </option>
          <option :value="fieldInfo[condition.column].type === 'text' ? 'NOT LIKE' : '!='">!=</option>
        </select>
        <input type="text" class="ib form-control"
            v-if="!condition.column"
            style="width: 40%;"/>
        <div class="ib vt"
            v-else-if="fieldInfo[condition.column].type === 'text'"
            style="width: 40%;">
          <input type="text" class="ib form-control"
            v-model="condition.field"/>
          <div class="mt-5 pull-right" v-if="condition.comp">
            半角カンマ（,）区切りで
            <span v-if="condition.comp === '=' || condition.comp === 'LIKE'"> (x OR y OR …) </span>
            <span v-if="condition.comp === '!=' || condition.comp === 'NOT LIKE'"> (NOT x AND NOT y AND …) </span>
            検索ができます
          </div>
        </div>
        <input type="number" class="ib form-control"
          v-else-if="fieldInfo[condition.column].type === 'number'"
          v-model="condition.field"
          min="1"
          style="width: 40%;"/>
        <div class="ib vt"
            v-else-if="fieldInfo[condition.column].type === 'select'"
            style="width: 40%;">
          <select class="ib form-control"
              v-model="condition.field"
              :multiple="fieldInfo[condition.column].multiple">
            <option v-for="option in selectOptions[fieldInfo[condition.column].option_name]"
                :value="option[fieldInfo[condition.column].option_key]"
                :key="option[fieldInfo[condition.column].option_key]">
              {{option[fieldInfo[condition.column].option_disp]}}
            </option>
          </select>
          <div class="mt-5 pull-right">[Shift] + クリックで範囲選択、[Ctrl] + クリックで複数選択ができます</div>
        </div>
        <my-calendar
          v-else-if="fieldInfo[condition.column].type === 'date'"
          v-model="condition.field"
          @input="onDateInput(condition)"
          width="200px">
        </my-calendar>
        <div class="ib" v-else-if="fieldInfo[condition.column].type === 'time'">
          <time-input class="ml-8"
            v-model="condition.hour"
            @input="onTimeInput(condition)"
            mode="hour"
            style="background-color: #ffffff;">
          </time-input>
          <span class="colon ib ml-2 mr-2">:</span>
          <time-input
            v-model="condition.minute"
            @input="onTimeInput(condition)"
            mode="minute"
            style="background-color: #ffffff;">
          </time-input>
        </div>
        <button class="btn btn-md btn-default vt"
            v-if="conditions.length > 1"
            @click="removeCondition(idx)">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <div>
      <button class="btn btn-md btn-info vt"
        v-if="conditions.length < 10"
        @click="addCondition">
        <i class="fa fa-plus"></i>追加
      </button>
    </div>
    <div class="ta-right">
      <button class="btn btn-lg btn-primary"
          :disabled="isSearchButtonDisabled || hasInputError"
          @click="doSearch">
        <i class="fa fa-search"></i>
        検索
      </button>
    </div>
  </div>
</template>

<script>
import dateMixin from '@/mixin/dateMixin'

export default {
  name: 'advanced-search-form',
  props: {
    conditions: {
      type: Array,
      required: true,
    },
    fieldInfo: {
      type: Object,
      required: true,
    },
    selectOptions: {
      type: Object,
      required: true,
    },
    isSearchButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    hasInputError() {
      const validateArr = [
        'column',
        'comp',
        'field'
      ]
      return this.conditions.some(condition => {
        return validateArr.some(k => {
          return condition[k] === null ||
             condition[k] === undefined ||
             condition[k] === '' ||
            condition[k].length === 0
        })
      })
    }
  },
  mixins: [dateMixin],
  methods: {
    onColumnChange(condition) {
      const info = this.fieldInfo[condition.column]
      condition.table = info.table
      condition.foreign_key = info.foreign_key
      condition.comp = ''
      condition.field = ''
      if (info.multiple) {
        condition.field = []
      }
      if (info.type === 'time') {
        condition.hour = '00'
        condition.minute = '00'
        condition.field = 0
      }
    },
    onDateInput(condition) {
      if (!this.isDateString(condition.field)) {
        condition.field = ''
      }
    },
    onTimeInput(condition) {
      const hour = condition.hour || '00'
      const minute = condition.minute || '00'
      condition.field = parseInt(hour + minute)
    },
    addCondition() {
      const condition = {
        table: '',
        column: '',
        comp: '',
        field: '',
        logicalOp: 'AND'
      }
      this.$emit('add-condition', condition)
    },
    removeCondition(idx) {
      this.$emit('remove-condition', idx)
    },
    doSearch(e) {
      this.$emit('do-search', e)
    }
  }
}
</script>
